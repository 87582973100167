.input-detail {
    display: block;
    width: 100%;
    height: 51px;
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    cursor: text;
    padding-left: 11px;
    padding-right: 11px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    
    color: #000000;
}

.input-detail:focus {
    border-color: #1990C6;
}