footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 28px;
    background: #F5E5C7;
}

.footer__text {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: 0.03em;
    
    color: #333333;
}