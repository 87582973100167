.cart__main-block {
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.cart__button {
    width: 24px;
    height: 24px;
    background: url('../../assets/icon/cart-icon.svg') no-repeat center;
    cursor: pointer;
}

.cart__button * {
    cursor: pointer;
}

.cart__count-items-block {
    position: absolute;
    padding: 3px 7px;
    background: #F5E5C7;
    border-radius: 45px;

    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    
    color: #333333;
}

.cart__elements-block {
    max-height: 400px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #BE4330 #f7f6f6;
    padding-top: 6px;
    padding-bottom: 6px;
}

.cart__items-block {
    position: absolute;
    min-width: 200px;
    max-width: 500px;
    width: max-content;
    background: #f7f6f6;
    bottom: -12px;
    right: 0px;
    transform: translateY(100%);
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.cart__item-block {
    position: relative;
    padding: 10px 16px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 32px;
}

.cart__item-main {
    display: flex;
    align-items: center;
}

.cart__item-name {
    margin-bottom: 12px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 16px;

    color: #333333;
}

.cart__item-price {
    margin-bottom: 8px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 16px;

    color: #333333;
}

.cart__item-incltax {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;

    color: #333333;
}

.cart__img-item {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 12px;
}

.cart__item-details {
    margin-left: 12px;
}

.cart__item-to-block {
    margin-left: 32px;
}

.cart__item-counter-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.cart__item-count {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 16px;

    color: #333333;
}

.cart__counter-block-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #A63D38;
    border-radius: 16px;
}

.button__separator {
    width: 1px;
    height: 14px;
    background: #CA6C67;
}

.button__count-item {
    width: 50px;
    height: 30px;
    background: none;
    cursor: pointer;
}

.button__count-item.plus {
    background-image: url('../../assets/icon/plus-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.button__count-item.minus {
    background-image: url('../../assets/icon/minus-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.btn__trash {
    width: 18px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.cart__is-empty-text {
    width: 100%;
    padding: 26px;
    text-align: center;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;

    color: #333333;
}

.cart__total-price {
    padding: 16px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.02em;

    color: #ffffff;
    background-color: #BE4330;
}

.cart__btn-checkout {
    position: sticky;
    bottom: 0px;
    width: 100%;
    padding: 18px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #F5E5C7;
    background: #BE4330;
    cursor: pointer;
    transition: .4s ease;
}

.cart__btn-checkout:hover {
    font-weight: 700;
    background: #eed6ac;
    color: #BE4330;
}

@media (max-width: 1030px) {
    .cart__main-block {
        position:static;
    }

    .cart__items-block {
        position: absolute;
        min-width: 200px;
        max-width: 100%;
        width: 100%;
        background: #f7f6f6;
        bottom: 0px;
        left: 0px;
        transform: translateY(0%, 0%);
        border-radius: 0px;
        box-shadow: none;
        overflow: hidden;
    }

    .cart__elements-block {
        max-height: calc(100vh - 195px);
    }

    .cart__main-block {
        margin: 0px;
    }
}