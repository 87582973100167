.shopify-section {
    position: relative;
    max-width: 1713px;
    width: 100%;
    margin: 32px auto;
}

.shopify-section__title {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 31px;

    color: #333333;
}

.shopify-section__description {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #333333;
    padding-right: 124px;
}

.shopify-section__main-card-block {
    margin-top: 32px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.shopify-section__selection-button-block {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    gap: 10px;
}

.shopify-section__button-select {
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    background: #803731;
    cursor: pointer;
    border-radius: 5px;
}

.shopify-section__button-select * {
    cursor: pointer;
}

.shopify-section__button-select.hidden {
    opacity: 0.3;
    cursor: unset;
}

.shopify-section__button-select.hidden * {
    cursor: unset;
}

@media (max-width: 800px) {
    .shopify-section__main-card-block {
        justify-content: space-evenly;
    }
}