.search__main-block {
    position: relative;
    max-width: 344px;
    min-width: 200px;
    width: min-content;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 15px;
    background: #FFD7D1;
    border-radius: 26px;
    margin-right: 32px;
}

.input__search {
    outline: none;
    background: transparent;
    cursor: text;
    
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.04em;
    
    color: rgba(43, 43, 43, 0.78);
}

.button__search {
    background: url('../../assets/icon/search-icon.svg') no-repeat center;
    width: 21px;
    height: 100%;
    cursor: pointer;
}

.cart__items-block.search {
    left: 0px;
    bottom: -32px;
    max-height: 500px;
    scrollbar-width: thin;
    scrollbar-color: #BE4330 #f7f6f6;
}

.cart__item-block.search {
    align-items: center;
}

.search-button__add-to-cart {
    padding: 12px 16px;
    background: #A63D38;
    border-radius: 12px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    
    color: #F7F6F6;
    cursor: pointer;
    transition: .3s ease;
}

.search-button__add-to-cart:hover {
    background: #e67c6c;
}

.search__item-search {
    padding: 16px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.02em;

    color: #333333;
    background-color: #F5E5C7;
}

@media (max-width: 1280px) {
    .search__main-block {
        position: relative;
        max-width: 344px;
        min-width: 20px;
        width: min-content;
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        background: #FFD7D1;
        border-radius: 26px;
        margin-right: 22px;
    }

    .input__search {
        outline: none;
        background: transparent;
        cursor: text;
        
        font-family: 'Alumni Sans';
        font-style: normal;
        font-weight: 500;
        width: 50px;
        font-size: 20px;
        letter-spacing: 0.04em;
        
        color: rgba(43, 43, 43, 0.78);
        transition: .3s ease;
    }
    
    .input__search:focus {
        width: 130px;
    }
}

@media (max-width: 1030px) {
    .cart__items-block.search {
        left: -113px;
        bottom: -21px;
        max-height: 500px;
        scrollbar-width: thin;
        scrollbar-color: #BE4330 #f7f6f6;
        border-radius: 0px 0px 12px 0px;
    }

    .input__search {
        width: max-content;
    }
}