header {
    position: sticky;
    display: flex;
    align-items: center;
    top: 0px;
    width: 100%;
    height: 80px;
    background: #BE4330;
    padding-left: 32px;
    padding-right: 32px;
    z-index: 30;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1030px) {
    header {
        justify-content: space-between;
    }
}

@media (max-width: 543px) {
    header {
        padding-left: 20px;
        padding-right: 20px;
    }
    
}
