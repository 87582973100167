.faq-accordion__main-block {
    max-width: 1250px;
    width: 100%;
    margin: 18px;
    border-bottom: 1px solid #a5a5a5;
    overflow: hidden;
    transition: 0.35s ease;
}

.faq-accordion__main-block.active {
    h4 {
        color: #df5a46;
    }
}

.faq-accordion-item-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    cursor: pointer;
}

.faq-accordion__title {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    cursor: pointer;
    color: #333333;
}

.faq-accordion-item-body {
    padding: 12px 45px 18px 20px;
}

.faq-accordion-item-body > p {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    color: #333333;
}

.accordion__icon {
    rotate: 0px;
    transition: 0.35s ease;
    cursor: pointer;
}

.accordion__icon.active {
    rotate: 45deg;
}

@media (max-width: 725px) {
    .faq-accordion__blocks {
        margin-left: 0px;
        margin-right: 0px;
    }

    .faq-accordion__main-block {
        margin-left: 0px;
        margin-right: 0px;
    }

    .faq-accordion__title {
        font-family: 'Alumni Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 15px;
        cursor: pointer;
        color: #333333;
    }

    .faq-accordion-item-body > p {
        font-family: 'Alumni Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
    }

    .faq-accordion-item-header {
        padding-left: 0px;
    }
}