.item-cart__detail-main-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item-cart__description-block {
    display: flex;
    gap: 10px;
}

.item-cart__image-item {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    object-fit: cover;
}

.item-cart__image-block {
    position: relative;
}

.item-cart__qty-block {
    position: absolute;
    right: -12px;
    bottom: -12px;
    background: #BE4330;
    border-radius: 61px;
    padding: 7px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    
    color: #FFFFFF;
}

.item-cart__name-price-block {
    margin-top: 3px;
}

.item-cart__name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;

    color: #333333;    
}

.item-cart__price {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    color: #333333;
}

.item-cart__total-item-cart {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    
    color: #333333;
}