li {
    list-style: none;
}

.header__menu-nav-block {
    display: flex;
    gap: 26px;
    height: 80px;
}

.header__menu-nav-block > li {
    height: 100%;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    transition: .3s ease;
}

.header__menu-nav-block > li:hover {
    color: #FFC3A9;
}

.header__modal-block {
    width: 215px;
    background: #BE4330;
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    transform: translate(-14px, 100%);
    bottom: 0px;
    color: #333333;
}

.header__modal-button {
    display: block;
    width: 100%;
    padding: 10px 14px;
    cursor: pointer;
    background: #BE4330;
    transition: .3s ease;
    border-radius: 8px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
}

.header__modal-button:hover {
    background: #F5E5C7;
    color: #333333;
}

.modal__round {
    position: absolute;
    left: -15px;
}

.modal__round.right {
    left: 215px;
    rotate: -90deg;
}

@media (max-width: 1280px) {
    .header__menu-nav-block {
        display: flex;
        gap: 18px;
        height: 80px;
    }
}